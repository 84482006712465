<template>

  <b-tabs class="tabs-primary-underlined light-tab-titles mt-4" fill lazy>
    <b-tab :title="chartAName">

      <div v-if="chartA.length === 0" class="pb-4">
        <no-content :text="$t('charts.analysis.noLoveGates')"/>
      </div>

      <div v-for="gate in chartA" :key="gate.gate +'.'+gate.isPersonal">
        <dynamic-zone-renderer v-if="getContent(gate)" :title="getContent(gate).name" is-toggleable
                               :components="getContent(gate).components"
                               @titleMouseover="highlightElement({type: types.gate, id: gate.gate})"
                               @titleMouseleave="clearAllHighlights"
        >
          <div v-if="!getContent(gate).missing_subscription_content">
            <div v-html="getContent(gate).introduction_audio"/>
            <h6>{{gate.gateDefinedOnChart ? $t('charts.analysis.loveGates.defined') : $t('charts.analysis.loveGates.undefined') }}</h6>
            <div v-html="getContent(gate)[gate.gateDefinedOnChart ? 'defined_text' : 'undefined_text']"/>
          </div>
        </dynamic-zone-renderer>
      </div>
    </b-tab>

    <b-tab :title="chartBName">
      <div v-if="chartB.length === 0" class="pb-4">
        <no-content :text="$t('charts.analysis.noLoveGates')"/>
      </div>

      <div v-for="gate in chartB" :key="gate.gate +'.'+gate.isPersonal">
        <dynamic-zone-renderer v-if="getContent(gate)" :title="getContent(gate).name" is-toggleable :components="getContent(gate).components"
                               @titleMouseover="highlightElement({type: types.gate, id: gate.gate})"
                               @titleMouseleave="clearAllHighlights">
          <div v-if="!getContent(gate).missing_subscription_content">
            <div v-html="getContent(gate).introduction_audio"/>

            <h6>{{gate.gateDefinedOnChart ? $t('charts.analysis.loveGates.defined') : $t('charts.analysis.loveGates.undefined') }}</h6>
            <div v-html="getContent(gate)[gate.gateDefinedOnChart ? 'defined_text' : 'undefined_text']"/>
          </div>
        </dynamic-zone-renderer>
      </div>
    </b-tab>
  </b-tabs>
</template>

<script>
import DynamicZoneRenderer from "../../../renderer/DynamicZoneRenderer.vue";
import NoContent from "../../../structure/NoContent.vue";
import HighlightingMixin from "@/mixins/HighlightingMixin";

export default {
  components: {NoContent, DynamicZoneRenderer},
  mixins: [HighlightingMixin],

  props: {
    isPersonal: Boolean,
    chartAName: String,
    chartBName: String,
    chartA: Array,
    chartB: Array,
    content: Array
  },
  data(){
    return{
      chartAContent: [],
      chartBContent: []
    }
  },

  methods:{
    getContent(gate) {
      return this.content.find(x => Number(x.gate_id.trim()) === gate.gate && x.type === (gate.isPersonal ? 'personal' : 'transcendence'))
    }
  }
}
</script>