<template>
  <div class="cart-item my-3 " style="margin-bottom:30px!important;">
    <div class="d-flex gap-5 align-items-center flex-shrink-0">
      <div class="mr-2 flex-shrink-0" >
        <div class=" mr-2  d-flex align-items-center justify-content-center " style="font-size:16px;height:50px;width:50px;border-radius:5px 10px;">
          <img v-if="item.chart_id" :src="config.imagesApi + '/thumbnails/' + item.chart_id" @error="(evt) => {
            const key = item.product ? item.product.key : item.key;
            evt.target.src = key === 'companionship' ? '/checkout-companionship-fallback.webp' : '/checkout-fallback.webp';
          }" />
          <product-icon v-else :product-key="item.product ? item.product.key : item.key"/>
        </div>
      </div>
      <div>
        <p class="font-500 mb-0">{{item.product ? item.product.name : item.name}}</p>
        <div class="mb-0 mbg-text-muted text-sm short-desc-cart-item" style="max-width:300px;"
             v-html="item.product? item.name : getByKey(`checkout.${item.key === 'transit_tool' ? 'subscription': 'single'}.${item.key}.short`)"/>
      </div>
      <div class="ml-auto">
        <b-button variant="outline-white" size="xs" class="ml-auto" @click="$emit('removeFromCart', item)">
          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
               stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash">
            <polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path></svg>
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>

import ProductIcon from "../ProductIcon.vue";
import {config} from "@/utils/config";
import ServerContentMixin from "@/mixins/ServerContentMixin";

export default{
  mixins: [ServerContentMixin],
  computed: {
    config() {
      return config
    }
  },
  components: {ProductIcon},

  props:{
    item: Object
  }
}
</script>
<style lang="scss">
.short-desc-cart-item{
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  p{
    font-size:10px;

  }
}
</style>