    <template>
      <div>
        <div class="d-flex chart-details  align-items-center px-1 gap-10" :class="wrapperClasses">
          <div class="initials-box flex-shrink-0 text-center mr-0">
            {{ getInitials(chart.meta.name, chart.meta.type === docTypes.CONNECTION_CHART) }}
          </div>
          <div style="max-width:75%">
            <h5 class="mb-1">{{chart.meta.name}}</h5>
            <p v-if="isPersonalChart(chart)" class="mb-0 text-sm mbg-text-muted">
              {{formatDate(chart.meta?.birthData?.time.utc, true)}} — {{chart.meta.birthData?.location?.city}}
            </p>
            <p v-else class="mb-0 text-sm mbg-text-muted">
              {{ $t('documentTypes.' + chart.meta.type) }}
            </p>
            <slot />
          </div>
        </div>
      </div>
    </template>

    <script>
    import ChartUtil from "@/mixins/ChartUtil";
    import docTypes from "../../helpers/docTypes";

    export default {
      computed: {
        docTypes() {
          return docTypes
        }
      },
      mixins: [ChartUtil],
      props: {
        chart: Object,
        wrapperClasses: String
      }
    }
    </script>