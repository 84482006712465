<template>
  <div class="pb-3">
    <dynamic-zone-renderer v-if="introComponents && introComponents.length" :add-paddings="false"
                           :components="introComponents" :title="$t('hd.properties.introduction')" is-toggleable/>
    <gates-of-love-list
        :chart-a-name="chartAName"
        :chart-b-name="chartBName"
        :chart-a="chartA"
        :chart-b="chartB"
        :content="general.filter(x => !!x)"
        :is-personal="isPersonal"/>
  </div>
</template>

<script>
import GatesOfLoveList from "@/components/charts/analysis-connection/partials/GatesOfLoveList.vue";
import DynamicZoneRenderer from "@/components/renderer/DynamicZoneRenderer.vue";

export default {
  props: {
    introComponents: Array,
    chartA: Array,
    chartB: Array,
    general: Array,
    chartAName: String,
    chartBName: String,
    isPersonal: Boolean
  },
  components: {DynamicZoneRenderer, GatesOfLoveList}

}
</script>